import React from 'react'
import styled from 'styled-components'
import { useMeasure } from 'react-use'
import { down } from 'styled-breakpoints'

export const Divider: React.FunctionComponent = (props) => {
  const [ref, { height }] = useMeasure<any>()
  return (
    <>
      <Spacer style={{ height }}>
        <Container
          ref={ref}
        >
          {props.children}
        </Container>
      </Spacer>
    </>
  )
}

export default Divider

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;

  padding: 0 var(--gutter);

  font-family: 'Libre Barcode 128 Text', cursive;
  font-size: 2.568rem;
  text-transform: uppercase;

  transition: opacity 0.2s linear;

  color: white;

  a {
    border: 1px solid var(--color-black);
    background: var(--color-white);
    white-space: nowrap;
    padding: 1.875rem 3.125rem;
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    border-top: 1px solid var(--color-black);
  }

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  ${down('medium')} {
    a {
      font-size: 1.5rem;
      padding: 1rem 1.5rem;
    }
  }
`

const Spacer = styled.div`
  height: 100px;
  margin: 4rem 0;
`
