import React from 'react'
import { FlexBox } from 'react-styled-flex'
import { useMeasure } from 'react-use'
import Divider from 'shared/ui/Divider'
import Page from 'shared/ui/Page'
import { down } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'
import styled from 'styled-components'
import NextImage from 'next/image'

import FooterImage from 'public/footer.png'
import IljaKarilampi from 'public/artists/ilja_karilampi.png'
import KatjaNovitskova from 'public/artists/katja_novitskova.png'
import TommyCash from 'public/artists/tommy_cash.png'
import NikKosmas from 'public/artists/nik_kosmas.png'
import Link from 'next/link'

const HomePage: React.FC = () => {
  const isMedium = useBreakpoint(down('medium'))

  const [ref, { height }] = useMeasure<HTMLImageElement>()

  return (
    <Page animatedLogo noBottomPadding>
      <BuyingSection gap={isMedium ? 0 : '50px'}>
        <DescriptionSection>
          <p>MutagenNFT is an NFT collection by internationally acclaimed artists and Solidity developers.</p>

          <p>It includes <b>40 NFTs (Geneses)</b> that are on-chain mutable and revenue-generating.</p>

          <p>Accompanied <b>by 4096 deflationary machine-generated NFTs (Mutagens)</b> that the Genesis holders have to burn to trigger mutations and thereby unlock their accumulated Print fees.</p>

          <p>The third token type is <b>Prints</b>, static snapshots that capture the state of a Genesis. The price of Prints runs on a bonding curve where 90% of the mint fee gets locked for instant liquidity for Print holders (you can always burn it back for ETH) and 5% each goes to the owner of the Genesis and Mutagen team.</p>

          <p>
            <a href={encodeURI("https://opensea.io/collection/mutagen?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Type&search[stringTraits][0][values][0]=Genesis&search[toggles][0]=BUY_NOW")} target="_blank">
              Collect Geneses
            </a>
          </p>

          <p>
            <a href={encodeURI("https://opensea.io/collection/mutagen?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Type&search[stringTraits][0][values][0]=Mutagen&search[toggles][0]=BUY_NOW")} target="_blank">
              Collect Mutagens
            </a>
          </p>

          <p>
            <Link href={`/artists`} passHref>
              Create Prints
            </Link>
          </p>
        </DescriptionSection>
      </BuyingSection>

      <Divider>
        <a href="https://discord.gg/9N94vkeQuV" target="_blank">
          Discord
        </a>
      </Divider>

      <Artists>
        <Link href={`/artists/ilja-karilampi`} passHref>
          <Artist>
            <NextImage src={IljaKarilampi} />
            <ArtistName>Ilja Karilampi</ArtistName>
          </Artist>
        </Link>
        <Link href={`/artists/katja-novitskova`} passHref>
          <Artist>
            <NextImage src={KatjaNovitskova} />
            <ArtistName>Katja Novitskova</ArtistName>
          </Artist>
        </Link>
        <Link href={`/artists/nik-kosmas`} passHref>
          <Artist>
            <NextImage src={NikKosmas} />
            <ArtistName>Nik Kosmas</ArtistName>
          </Artist>
        </Link>
        <Link href={`/artists/tommy-cash`} passHref>
          <Artist>
            <NextImage src={TommyCash} />
            <ArtistName>Tommy Cash</ArtistName>
          </Artist>
        </Link>
      </Artists>

      <FullWidthImage ref={ref} src="/svg/golova.svg" draggable="false" />
      <Spacer style={{ height }} />

      <DescriptionSection>
        <h1>Introduction</h1>

        <p>
        Our goal is to onboard more institutionally established artists into the NFT world in a more fun and creative way than just one-off NFT artworks. 
        </p>
        
        <p>
        Ethereum enables a lot of new features that were not possible before, and we aim to introduce these opportunities to ‘traditional’ artists on the one hand and present quality contemporary art to the NFT world on the other, thereby expanding the boundaries of the current NFT market. Mutagen mixes institutionally established art with (Solidity) developers.
        </p>
        
        <p>
        Mutagen is supported by <a href="https://twitter.com/EdgarAronov" target="_blank">edgar.eth</a>, <a href="https://www.artnews.com/art-news/news/tallinn-estonia-art-cities-to-watch-1234597747/" target="_blank">Temnikova & Kasela Gallery</a>, and <a href="https://twitter.com/seikatsu" target="_blank">Sten Tamkivi</a>.
        </p>

        <a href="https://mutagen.gitbook.io/mutagen/" target="_blank">Read more from docs 👀</a>       
      </DescriptionSection>
      
      <Divider>
        <a href="https://twitter.com/MutagenNFT" target="_blank">
          Twitter
        </a>
      </Divider>

      <TOSSection>
        <a href="/terms-of-service.pdf" target="_blank">
          Terms of Service
        </a>
      </TOSSection>
      
      <DescriptionSection fullWidthMobile>
        <Image>
          <NextImage src={FooterImage} draggable="false" />
        </Image>
      </DescriptionSection>

    </Page>
  )
}

export default HomePage

const Spacer = styled.div`
  height: 100px;
`


const Artists = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  column-gap: var(--gutter);
  row-gap: var(--gutter);
  margin-bottom: 2rem;

  ${down('medium')} {
    grid-template-columns: 1fr 1fr;
  }
`

const Artist = styled.a`
  text-decoration: none;

  img {
    border: 1px solid var(--color-black) !important;
  }
`

const ArtistName = styled.h1`
  text-align: center;

  ${down('medium')} {
    font-size: 1.2em;
  }

  ${down('small')} {
    font-size: 0.8em;
  }
`


const BuyingSection = styled(FlexBox)`
  justify-content: center;
  margin: 8em auto;
  max-width: 100%;

  ${down('medium')} {
    flex-direction: column;
    margin: 4rem auto;
    margin-top: 0;
  }
`

const FullWidthImage = styled.img`
  width: 100%;
  position: absolute;
  left: 0;
  user-select: none;
`

const Image = styled.div`
  max-width: 100%;
  user-select: none;

  img {
    border: 1px solid var(--color-black) !important;
  }
`

const DescriptionSection = styled.div`
  max-width: 900px;
  margin: auto;
  font-size: 1.5rem;
  text-align: justify;

  ${down('small')} {
    font-size: 1.2em;
  }

  h1 {
    font-size: 2.5rem;
  }

  h1, h2 {
    text-align: center;
    line-height: 145%;
    margin-top: 8.125rem;
    margin-bottom: 4.0625rem;
  }

  h2 {
    color: transparent;
    -webkit-text-stroke: 1px black;
    font-weight: bold;
  }

  p, ol, ul {
    font-size: 1em;
    line-height: 145%;
  }

  ${(props: { fullWidthMobile?: boolean }) => props?.fullWidthMobile && `
    line-height: 0;
  `}

  ${down('medium')} {
    ${(props: { fullWidthMobile?: boolean }) => props?.fullWidthMobile && `
      max-width: 100%;
      position: absolute;
      left: 0;
      right: 0;
    `}
  }
`

const TOSSection = styled(DescriptionSection)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const BuyingDescription = styled(DescriptionSection)`
  flex: 1 0 calc(50% - 25px);
  box-sizing: border-box;
  margin: 1rem auto;

  p {
    margin: 0;
  }
  
  ${down('small')} {
    margin-top: 2rem !important;
  }
`

